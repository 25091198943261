import React, { useState } from 'react';
import './OurServices.css';

function OurServices() {
  const [showVCDetails, setShowVCDetails] = useState(false);
  const [showConsultingDetails, setShowConsultingDetails] = useState(false);
  const [showITDetails, setShowITDetails] = useState(false);

  const toggleVCDetails = () => {
    setShowVCDetails(!showVCDetails);
  };

  const toggleConsultingDetails = () => {
    setShowConsultingDetails(!showConsultingDetails);
  };

  const toggleITDetails = () => {
    setShowITDetails(!showITDetails);
  };

  return (
    <div className="our-services">
      <section className="service">
        <h2>Venture Capital and Private Equity</h2>
        <p>Investment strategies and portfolio management.</p>
        <button onClick={toggleVCDetails}>
          {showVCDetails ? 'Hide Details' : 'Learn More'}
        </button>
        {showVCDetails && (
          <div className="vc-details">
            <h3>Our Process</h3>
            <ol>
              <li>
                <strong>Apply for Funding:</strong> Submit your business plan
                and let us know about your vision.
              </li>
              <li>
                <strong>Evaluation & Due Diligence:</strong> We assess your
                business’s feasibility, scalability, and market potential.
              </li>
              <li>
                <strong>Strategic Partnership & Growth:</strong> Beyond funding,
                we provide mentorship, resources, and operational support to
                ensure success.
              </li>
            </ol>
            <p>
              Ready to take the next step?{' '}
              <a href="#apply">Apply for funding now</a>.
            </p>
          </div>
        )}
      </section>

      <section className="service">
        <h2>Consulting Services</h2>
        <p>AI, Fintech, and STEM consulting, including data collection, analysis, and market investigation.</p>
        <button onClick={toggleConsultingDetails}>
          {showConsultingDetails ? 'Hide Details' : 'Learn More'}
        </button>
        {showConsultingDetails && (
          <div className="consulting-details">
            <h3>Our Consulting Services</h3>
            <p>
              We specialize in providing consulting services across the AI, intech**, and STEM sectors. Our team helps businesses navigate the complexities of these industries with expertise in data collection, market research, and actionable reporting.
            </p>
            <h4>Our Focus Areas</h4>
            <ul>
              <li><strong>AI and Machine Learning:</strong> We assist companies in leveraging AI for business growth, from strategy to implementation.</li>
              <li><strong>Fintech Innovation:</strong> Helping financial organizations adopt cutting-edge technology for secure and efficient solutions.</li>
              <li><strong>STEM Research and Development:</strong> Supporting the advancement of scientific research and technological innovation.</li>
            </ul>
            <h4>Our Services</h4>
            <ul>
              <li><strong>Data Collection:</strong> Gathering and structuring relevant data from various sources.</li>
              <li><strong>Data Analysis:</strong> Using statistical and AI-driven methods to analyze data and draw actionable insights.</li>
              <li><strong>Market Investigation:</strong> Conducting thorough market research to understand trends, competitors, and customer needs.</li>
              <li><strong>Reporting:</strong> Generating detailed reports with clear insights, strategic recommendations, and actionable results.</li>
            </ul>
            <p>
              Ready to unlock the potential of your business?{' '}
              <a href="#consulting-contact">Contact our consultants today</a>.
            </p>
          </div>
        )}
      </section>

      <section className="service">
        <h2>Financial IT Solutions</h2>
        <p>Comprehensive IT solutions for the financial sector.</p>
        <button onClick={toggleITDetails}>
          {showITDetails ? 'Hide Details' : 'Learn More'}
        </button>
        {showITDetails && (
          <div className="it-details">
            <h3>Our IT Solutions</h3>
            <p>
              Our Financial IT Solutions are designed to help financial institutions, startups, and enterprises streamline their operations, enhance security, and comply with regulatory standards.
            </p>
            <h4>Key Solutions We Offer</h4>
            <ul>
              <li><strong>Core Banking Systems:</strong> Scalable, secure platforms to manage banking operations.</li>
              <li><strong>Data Security & Compliance:</strong> Ensure data privacy and meet all industry regulations.</li>
              <li><strong>AI & Machine Learning:</strong> Automate processes and enhance decision-making with AI-driven tools.</li>
              <li><strong>Blockchain Integration:</strong> Secure and transparent financial transactions using blockchain technology.</li>
              <li><strong>Payment Solutions:</strong> Enable seamless and secure payment processing for businesses and consumers.</li>
            </ul>
            <p>
              Transform your financial IT infrastructure with our solutions.{' '}
              <a href="#it-contact">Contact us for more information</a>.
            </p>
          </div>
        )}
      </section>
    </div>
  );
}

export default OurServices;
