import React from 'react';
import './AboutUs.css';
//import whoWeAreImage from '/company-overview.jpg'; // Adjust the path to your image file

function AboutUs() {
  return (
    <div className="about-us">
      {/* Who We Are Section */}
      <section className="who-we-are">
        <h2>Who We Are</h2>
        <div className="who-we-are-content">
          <img src="/images/company-overview.jpg" alt="Team" className="who-we-are-image" />
          <div className="who-we-are-text">
            <p>
              TT Tech LTD is a London-based company specializing in IT services for financial companies and institutions worldwide. With extensive expertise and experience in the global financial market, we focus on financial data processing and IT solutions. Our commitment to innovation drives us to invest in cutting-edge IT companies across Europe.
            </p>
          </div>
        </div>
      </section>

      {/* Mission and Vision Section */}
      <section className="mission-vision">
        <h2>Mission and Vision</h2>
        <div className="content">
          <div className="mission">
            <h3>Our Mission</h3>
            <ul>
              <li>Enable businesses to harness the power of <strong>AI and Fintech</strong> to achieve their goals.</li>
              <li>Empower organizations to make data-driven decisions through precise <strong>data collection</strong> and <strong>analysis</strong>.</li>
              <li>Bridge the gap between traditional business practices and the rapidly evolving <strong>technological landscape</strong>.</li>
            </ul>
          </div>
          <div className="vision">
            <h3>Our Vision</h3>
            <p>
              We envision a world where technology empowers businesses to create meaningful change. By combining human ingenuity with advanced technology, we aim to:
            </p>
            <ul>
              <li>Transform industries through <strong>next-generation IT solutions</strong>.</li>
              <li>Provide actionable insights that lead to measurable outcomes.</li>
              <li>Support the growth of <strong>STEM sectors</strong>, fostering innovation for future generations.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
